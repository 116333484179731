@import '../constants.scss';

.menu-container {
  /*position: sticky;
  top: 0;*/
}

.menu {
    color: $menu-text-color;
    text-transform: uppercase;
    text-align: center;
    padding: 10px $app-lateral-margin;
    font-size: 14px;
    font-weight: bold;
    min-height: 40px;
    position: relative;
    background: $section-background;
    a:not(.fa-facebook) {
        margin-right: 40px;
        text-decoration: none;
        color: $menu-text-color;
        padding-bottom: 5px;
        &:hover {
            border-bottom: 2px solid $highlight-color;
            color: $highlight-color;
        }
    }
    .fa-facebook {
        background: #3B5998;
        color: white;
        border-radius: 50%;
        padding: 3px;
        width: 30px;
        height: 30px;
        text-decoration: none;
        position: absolute;
        right: 30px;
        top: 6px;
        &:hover {
            opacity: 0.7;
        }
        &:before{
          font-size: 20px;
          vertical-align: -30%;
        }
    }
    
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 30px;
    height: 22px;
    float: left;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $menu-text-color;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: $highlight-color;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: absolute;
    top: 0px;
    height: 100%;
    left: 0px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    color: #b8b7ad !important;
    display: inline-block;
    margin-bottom: 20px;
    outline: none !important;
    &:hover {
        color: $highlight-color !important;
        border-bottom: none !important;
    }
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0px;
  }