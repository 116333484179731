@import '../constants.scss';

.footer {
  text-align: center;
  padding: 5px 0px 5px 0px;
  background: $section-background;
  color: $menu-text-color;
  width: 100%;
  label {
    text-align: center;
    font-weight: bold;
  }
}