@import './constants.scss';

html, body, #root {
    margin: 0;
    height: 100%;
}

.application {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: url('/photos/background.jpg');
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
}

.full-page {
    min-height: 100%;
    flex-shrink: 0;
}

.app-text, h3 {
    font-family: 'Lato', sans-serif;
    color: white;
}

.app-text {
    font-size: 16px;
}

p {
    text-indent: 20px;
    text-align: justify;
}

h1 {
    color: $menu-text-color;
    font-family: $header-font-family;
    font-size: 40px;
}

.hide {
    display: none;
}

.isPhone {
    .header {
        margin: 0px $phone-lateral-margin;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    .glyphicon-asterisk.first {
        padding-left: 10px;
    }
    h2 {
        font-size: 18px;
    }
    .content {
        padding: 0 $phone-lateral-margin;
    }
    .menu {
        padding: 10px $phone-lateral-margin;
    }
}