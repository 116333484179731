@import '../constants.scss';

.content-container {
    flex-grow: 1;
    margin: 20px $app-lateral-margin;
}

.content {
    margin: 0 auto;
    max-width: 1024px;
    padding: 20px;
    background: $section-background;

    h2 {
        width: fit-content;
        padding-bottom: 10px;
        border-bottom: 2px solid $highlight-color;
        margin: 0px 0px 30px 0px;
        font-family: $header-font-family;
        font-size: 30px;
        color: white;
    }
}