@import '../constants.scss';

.header {
    margin: 0px $app-lateral-margin;
    display: flex;
    min-height: 80px;
}

.header-title {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    a {
        color: $menu-text-color;
        text-decoration: none;
    }
}

.glyphicon-asterisk {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 20px;
    &.first {
        padding-left: 15px;
    }
	background: radial-gradient(yellow 0%, red 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
