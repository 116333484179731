.notice {
  color: red;
}

li {
  margin-bottom: 5px;
}

.glyphicon-warning-sign {
  color: yellow;
  float: left;
}