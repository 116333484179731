.image-gallery {
    width: 100%;
    background: transparent;
    .image-gallery-content {
        text-align: center;
        text-align: -webkit-center;
    }
    .image-gallery-slide-wrapper {
        max-width: 100%;
        width: 700px;
        height: 500px;
        margin: auto;
        vertical-align: middle;
        display: table-cell;
    }
    .image-gallery-image {
        text-align: center;
        background: transparent;
        img{
            max-height: 500px;
            width: auto;
            max-width: 100%;
        }
    }
    .image-gallery-slide {
        background: transparent;
    }
}