
.video-container {
    max-height: 432px;
    max-width: 768px;
    margin: auto;
    text-align: center;
}

.video {
    width: 100%;
}

.youtube-video {
    height: 432px;
    width: 768px;
    max-width: 100%;
}

.about-description {
    margin-bottom: 30px;
}

.welcome {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}