@import '../constants.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  h3, p {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.list-item {
  flex-grow: 1;
  flex-shrink: 0;
  width: 300px;
  min-width: 50%;
  margin-bottom: 20px;
  &.text-list-item {
    margin: auto;
  }
  img {
    width: 100%;
    height: 100%;
  }
}