.contact-label {
    float: left;
    font-weight: bold;
    width: 90px;
}

.contact-text {
    display: inline-block;
}

.contact-group {
    margin-bottom: 20px;
}

.contact-data {
    float: left;
    margin-right: 40px;
    margin-bottom: 20px;
}

.googleMap {
    max-width: 100%;
    width: 600px;
    height: 400px;
    display: inline-block;
    iframe {
        width: 100%;
        height: 100%;
    }
}

.glyphicon-earphone {
    padding-right: 5px;
}